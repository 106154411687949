import React from "react";
import Layout from "../Layout";

const CareerAnalysis = () => {
  return (
    <div>
      <Layout>This is career analysis</Layout>
    </div>
  );
};

export default CareerAnalysis;
