import React from "react";
import Layout from "../Layout";

const LeaderBoard = () => {
  return (
    <div>
      <Layout>This is leader board</Layout>
    </div>
  );
};

export default LeaderBoard;
